import moment from 'moment'
export const periodConstructor = (
    from?: any,
    to?: any,
    todayMarker: any = {},
) => {
    if (!from && !to) return todayMarker

    if (from && !to)
        return {
            ...todayMarker,
            [from]: { color: '#F0E5FF', textColor: '#6200EA', selected: true },
        }

    let startDate = moment(from, 'YYYY-MM-DD')
    let endDate = moment(to, 'YYYY-MM-DD')
    let diff = moment(endDate).diff(startDate, 'd')
    if (diff < 0) {
        let temp = startDate
        startDate = endDate
        endDate = temp
        diff = Math.abs(diff)
    }
    const dates: any = {}
    for (let i = 0; i <= diff; i++) {
        const isFirst = i == 0,
            isLast = i == diff
        const date = startDate.add(isFirst ? 0 : 1, 'd').format('YYYY-MM-DD')
        dates[date] = {
            color: '#F0E5FF',
            textColor: '#6200EA',
            startingDay: isFirst,
            endingDay: isLast,
        }
    }
    return dates
}

export const formatedDate = (dateString: string, format: string) => {
    return moment(dateString, 'YYYY-MM-DD').format(format)
}

export const getMonthNames = () => {
    // Get the current locale
    const currentLocale = Intl.DateTimeFormat().resolvedOptions().locale;
  
    // Create an array of month names
    const monthNames = Array.from({ length: 12 }, (_, i) =>
      new Intl.DateTimeFormat(currentLocale, { month: "long" }).format(
        new Date(0, i)
      )
    );
  
    return monthNames;
  };
  


